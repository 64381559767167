import React from 'react';
import AdminLogin from '../components/pages/AdminLogin';
import Admin from '../components/pages/Admin';
import NotFound from '../components/pages/NotFound';
import AdminMain from '../components/pages/adminSubpages/AdminMain';
import AdminInformation from '../components/pages/adminSubpages/AdminInformation';
import AdminPortfolio from '../components/pages/adminSubpages/AdminPortfolio';
import AdminInquiries from '../components/pages/adminSubpages/AdminInquiries';
import ProjectDetail from '../components/pages/adminSubpages/ProjectDetail';
import InformationAbout from '../components/pages/adminSubpages/InformationAbout';
import InformationSolution from '../components/pages/adminSubpages/InformationSolution';
import InformationContact from '../components/pages/adminSubpages/InformationContact';
import LandingPage from '../components/pages/LangindPage';
import { Route } from 'react-router-dom';
import About from '../components/pages/About';
import AigcWork from '../components/pages/AigcWork';
import AigcSolution from '../components/pages/AigcSolution';
import Contact from '../components/pages/Contact';
import NewAbout from '../components/pages/NewAbout';
import AboutWrapper from '../components/pages/AboutWrapper';
import AigcWorkDetailWrapper from '../components/pages/AigcWorkDetailWrapper';
import IRPage from '../components/pages/IRPage';

export interface RouteItem {
  path: string;
  element: React.ReactElement;
  exact?: boolean;
  needLogin?: boolean;
  children?: RouteItem[];
}

export function renderRoute(route: RouteItem, index: number) {
  return (
    <Route key={index} path={route.path} element={route.element}>
      {route.children?.map(renderRoute)}
    </Route>
  );
}

const routes: RouteItem[] = [
  {
    path: '/',
    element: <LandingPage />,
    exact: true,
  },
  {
    path: '/about',
    element: <AboutWrapper />,
    exact: true,
  },
  {
    path: '/aigc-work',
    element: <AigcWork />,
    exact: true,
  },
  {
    path: '/aigc-work/:id',
    element: <AigcWorkDetailWrapper />,
    exact: true,
  },
  {
    path: '/aigc-solution',
    element: <AigcSolution />,
    exact: true,
  },
  {
    path: '/contact',
    element: <Contact />,
    exact: true,
  },
  {
    path: '/login',
    element: <AdminLogin />,
    exact: true,
  },
  {
    path: '/news',
    element: <IRPage />,
    exact: true,
  },
  // {
  //   path: '/test',
  //   element: <ThreeTest />,
  //   exact: true,
  // },
  {
    path: '*',
    element: <NotFound />,
  },
];

export default routes;
