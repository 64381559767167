import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { ReactComponent as ArrowUp } from '../../assets/images/arrow-up.svg';
import { ReactComponent as Logo } from '../../assets/images/directors-tech-logo.svg';
import { ReactComponent as HambergerMo } from '../../assets/images/mo_ham.svg';
import LottieTest from '../LottieTest';
import aboutData from '../../assets/videos/about.json';
import contactData from '../../assets/videos/Contact.json';
import productData from '../../assets/videos/Product.json';
import solutionData from '../../assets/videos/Solution.json';
import SloganSectionMo from '../about/SloganSectionMo';
import CompanyNameSectionMo from '../about/CompanyNameSectionMo';
import ClientSectionMo from '../about/ClientSectionMo';
import AigcShortFormMo from '../about/AigcShortFormMo';
import AigcSaasSectionMo from '../about/AigcSaasSectionMo';
import AiDressingSectionMo from '../about/AiDressingSectionMo';
import SalesDrivenMo from '../about/SalesDrivenMo';
import BrandingDrivenSectionMo from '../about/BrandingDrivenSectionMo';
import ContactSectionMo from '../about/ContactSectionMo';
import ContactFormSectionMo from '../about/ContactFormSectionMo';
import MainNavPanel from '../navigation/MainNavPanel';
import { Link } from 'react-router-dom';
import HamburgerNavButton from '../navigation/HamburgerNavButton';
import useScrollDirection from '../../hooks/useScrollDirection';
import { useWorkStore } from '../../store/useStore';

const ContainerStyle = styled.div`
  .aboutTop {
    margin-top: 51%;
  }

  #about,
  #aigc-product,
  #aigc-solution,
  #contact {
    height: 4px;
  }

  .block-style {
    width: 100vw;
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    padding-left: 32px;
    padding-right: 32px;
  }

  .lottie-style {
    display: flex;
    color: #fff;
    margin-left: -3.2%;
  }

  .lottie-about {
    margin-bottom: 80px;
  }

  .lotti-title {
    margin-bottom: 80px;
    font-size: 72px;
    font-weight: 900;
    line-height: 1;
  }

  .about-header {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 28px 30px;
    z-index: 100;
  }

  .right-nav {
    position: fixed;
    width: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-size: 9px;
    font-weight: 500;
    transform: rotate(90deg);
    transform-origin: top right;
    top: 100%;
    right: 10px;
    //pointer-events: auto;
    gap: 24px;

    .right-line-01 {
      width: 204px;
      height: 1px;
      border-bottom: 1px solid #fff;
      opacity: 0.16;
    }

    .right-line-02 {
      width: 8px;
      height: 1px;
      border-bottom: 1px solid #fff;
      opacity: 0.16;
    }

    .right-line-03 {
      width: 8px;
      height: 1px;
      border-bottom: 1px solid #fff;
      opacity: 0.16;
    }

    .right-line-04 {
      width: 204px;
      height: 1px;
      border-bottom: 1px solid #fff;
      opacity: 0.16;
    }

    & > svg {
      transform: rotate(270deg);
    }
  }

  .about-footer {
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #fff;
    text-align: center;
    font-size: 10px;
    line-height: 1.5;
    font-family: 'Arial', sans-serif;
    padding: 40px 0;

    @media screen and (max-width: 375px) {
      font-size: 8px;
    }

    .about-footer-info {
      display: flex;
      justify-content: center;
      gap: 20px;
    }

    .company-name,
    .representative,
    .business-number,
    .address,
    .copyright {
      color: white;
      z-index: 1;
    }
  }
`;

const ArrowUpStyle = styled(ArrowUp)`
  stroke: #8fff00;
  color: #8fff00;

  & > path {
    stroke: #8fff00;
  }

  &:hover {
    stroke: #8fff00;
  }
`;

const HoverAre = styled.div`
  width: 30px;
  height: 30px;
  //border: 1px solid #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: all;

  &:hover {
    cursor: pointer;
  }

  & > svg {
    transform: rotate(-90deg);
  }
`;

const playerContainerStyle = {
  position: 'fixed',
  width: '100%',
  height: '100%',
  overflow: 'hidden',
} as React.CSSProperties;

const playerStyleMo = {
  position: 'fixed',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  height: '100vh',
  width: '100vw',
  objectFit: 'cover',
  border: 'none',
  padding: '0',
  margin: '0',
} as React.CSSProperties;

const NewAboutMo: React.FC = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [videoRef, setVideoRef] = useState<HTMLVideoElement | null>(null);
  const [currentSection, setCurrentSection] = useState(1);

  const scrollDirection = useScrollDirection();

  const { current, setCurrent } = useWorkStore();

  const scrollToTop = () => {
    const currentScroll = window.scrollY;
    console.log('currentScroll:', currentScroll);
    window.scrollBy({
      // scroll to top position
      top: -currentScroll,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    setCurrent('all');
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            if (scrollDirection === 'down') {
              switch (entry.target.id) {
                case 'about':
                  setCurrentSection(1);
                  break;
                case 'aigc-product':
                  setCurrentSection(2);
                  break;
                case 'aigc-solution':
                  setCurrentSection(3);
                  break;
                case 'contact':
                  setCurrentSection(4);
                  break;
                default:
                  break;
              }
            }
          } else {
            // 스크롤 업 시 섹션 퇴장 감지
            if (scrollDirection === 'up') {
              switch (entry.target.id) {
                case 'about':
                  setCurrentSection(1);
                  break;
                case 'aigc-product':
                  setCurrentSection(1);
                  break;
                case 'aigc-solution':
                  setCurrentSection(2);
                  break;
                case 'contact':
                  setCurrentSection(3);
                  break;
                default:
                  break;
              }
            }
          }
        });
      },
      { root: null, rootMargin: '0px', threshold: 0.1 }
    );

    // 관찰할 타겟 요소 추가
    const targets = document.querySelectorAll('.section');
    targets.forEach((target) => observer.observe(target));

    // 클린업 함수
    return () => observer.disconnect();
  }, [scrollDirection]);

  useEffect(() => {
    if (videoRef) {
      videoRef.play().catch((error) => console.log('Auto-play failed'));
    }
  });

  return (
    <ContainerStyle>
      <div className="video-container" style={playerContainerStyle}>
        <video
          ref={setVideoRef}
          autoPlay
          loop
          muted
          playsInline
          style={playerStyleMo}
          src={'videos/about_BG_M.mp4'}
        />
      </div>
      {scrollDirection !== 'down' && (
        <div className="about-header">
          <Link to="/">
            <Logo />
          </Link>
          {!isMenuOpen && (
            <HamburgerNavButton
              isMenuOpen={isMenuOpen}
              setIsMenuOpen={setIsMenuOpen}
            />
          )}
        </div>
      )}
      <div id="about" className="aboutTop section"></div>
      <div className="block-style">
        <SloganSectionMo />
      </div>
      <CompanyNameSectionMo />
      <ClientSectionMo />
      <div id="aigc-product" className="section"></div>
      <div className="block-style lottie-style lotti-title">
        <LottieTest
          width={window.innerWidth * 0.9}
          animationData={productData}
        />
      </div>
      <AigcShortFormMo />
      <AigcSaasSectionMo />
      <AiDressingSectionMo />
      <div id="aigc-solution" className="section"></div>
      <div className="block-style lottie-style">
        <LottieTest
          width={window.innerWidth * 0.9}
          animationData={solutionData}
        />
      </div>
      <SalesDrivenMo />
      <BrandingDrivenSectionMo />
      <div id="contact" className="section"></div>
      <div className="block-style lottie-style">
        <LottieTest
          width={window.innerWidth * 0.85}
          animationData={contactData}
        />
      </div>
      <ContactFormSectionMo />
      <ContactSectionMo />
      <div className="about-footer">
        <div className="about-footer-info">
          <div className="company-name">(주)디렉터스테크</div>
          <div className="representative">대표자: 이재철</div>
          <div className="business-number">사업자등록번호: 294-88-03070</div>
        </div>
        <div className="address">
          주소: 서울 강남구 테헤란로 516 정현빌딩 4층, 디렉터스테크 (우)06180
        </div>
        <div className="copyright">
          Copyright 2023 DIRECTORSTECH all rights reserved
        </div>
      </div>
      <div className="right-nav">
        <div className="right-line-01"></div>
        <div>{"DIRECTOR'S TECH"}</div>
        <div className="right-line-02"></div>
        <div style={{ color: '#8fff00' }}>{`0${currentSection}`}</div>
        <div className="right-line-03"></div>
        <div>ABOUT</div>
        <div className="right-line-04"></div>
        <ArrowUpStyle onClick={() => scrollToTop()} />
      </div>
      {isMenuOpen && (
        <MainNavPanel isMenuOpen={isMenuOpen} setMenuOpen={setIsMenuOpen} />
      )}
    </ContainerStyle>
  );
};

export default NewAboutMo;
