import React from 'react';
import styled from 'styled-components';
import { isMobile } from 'react-device-detect';

interface ArrowProps {
  style?: React.CSSProperties;
  className?: string;
  onClick?: () => void;
}

const ArrowContainer = styled.div`
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 100;
  position: absolute !important;
  top: calc(100% - 60px);

  @media (max-width: 768px) {
    top: 50%;
  }

  &::before {
    content: none !important;
  }
`;

const SvgIcon = styled.svg`
  width: ${isMobile ? '28px' : '48px'};
  height: ${isMobile ? '28px' : '48px'};
`;

export const CarouselPrevArrow: React.FC<ArrowProps> = ({
  style,
  className,
  onClick,
}) => {
  return (
    <ArrowContainer
      className={className}
      style={{
        ...style,
        left: isMobile ? '12px' : '36px',
      }}
      onClick={onClick}
    >
      <SvgIcon viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
        <circle
          cx="24"
          cy="24"
          r="23"
          fill="none"
          stroke="white"
          strokeWidth="2"
        />
        <polyline
          points="28,16 20,24 28,32"
          fill="none"
          stroke="white"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </SvgIcon>
    </ArrowContainer>
  );
};

export const CarouselNextArrow: React.FC<ArrowProps> = ({
  style,
  className,
  onClick,
}) => {
  return (
    <ArrowContainer
      className={className}
      style={{
        ...style,
        right: isMobile ? '12px' : '36px',
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'flex-start',
      }}
      onClick={onClick}
    >
      <SvgIcon viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
        <circle
          cx="24"
          cy="24"
          r="23"
          fill="none"
          stroke="white"
          strokeWidth="2"
        />
        <polyline
          points="20,16 28,24 20,32"
          fill="none"
          stroke="white"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </SvgIcon>
    </ArrowContainer>
  );
};
