import React, { useEffect, useState, useRef, useCallback } from 'react';
import styled from 'styled-components';
import Logo from '../navigation/Logo';
import HambergerNavButton from '../navigation/HamburgerNavButton';
import MainNavPanel from '../navigation/MainNavPanel';
import AIGCNavTab from '../navigation/AIGCNavTab';
import PortfolioItem from '../portfolio/PortfolioItem';
import axios from 'axios';
import { Link, useLocation } from 'react-router-dom';
import AigcWorkDetail from './AigcWorkDetail';
import AigcWorkDetailMo from './AigcWorkDetailMo';
import { isMobile } from 'react-device-detect';
import process from 'process';
import { ReactComponent as Fold } from '../../assets/images/Ic_Fold.svg';
import { ReactComponent as Spread } from '../../assets/images/Ic_Spread.svg';
import { ReactComponent as TopBotton } from '../../assets/images/Btn_Top_Arrow.svg';
import { ReactComponent as TopBottonMo } from '../../assets/images/Btn_Top_Arrow_mo.svg';
import useSWR from 'swr';
import { useWorkStore } from '../../store/useStore';

interface Results {
  count: number;
  next: string | null;
  previous: string | null;
  results: Project[];
}

interface Project {
  id: number;
  thumbnail: string;
  collaboration_brand: string;
  category:
    | '360-premium'
    | 'one-point-creative'
    | 'customized-creative'
    | 'luxury'
    | 'fooh'
    | 'aigc-saas'
    | 'ai-dressing';
  sub_title: string;
  date: string;
  kv_link?: string;
  vidio_direction: 'horizontal' | 'vertical' | 'square';
  is_public: boolean;
  logo: string;
  logo_bg_color: string;
  description: string;
  tech: string;
  keywords: string;
}

const ContainerStyle = styled.div<{ mobile: boolean }>`
  width: 100vw;
  cursor: ${(props) => (props.mobile ? 'auto' : 'none')};
  overflow-x: hidden;

  .filtered-cursor {
    //position: relative;
    width: 100%;
    height: 100%;
  }

  .header {
    width: 100vw;
    height: 100px;
    padding: 20px 80px;

    @media (max-width: 768px) {
      padding: 18px 28px;
      > a {
        display: block;
        margin-top: 10px;
      }
    }
  }

  .title {
    padding: 0 80px;

    @media (max-width: 768px) {
      padding: 0 20px;
    }

    .title-text {
      font-size: 7rem;
      font-family: 'Noway', sans-serif;
      font-weight: 700;

      @media (max-width: 768px) {
        font-size: 4rem;
        line-height: 1;
        margin-bottom: 5px;
      }
    }
  }

  .nav-tab-wrapper {
    padding: 20px 80px;
    overflow-x: auto;

    @media (max-width: 768px) {
      overflow-x: auto;
      padding: 20px 20px;
    }
  }

  .works-wrapper {
    padding: 20px 80px;
    overflow-x: hidden;

    @media (max-width: 768px) {
      padding: 20px;
    }
  }

  .works-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 10px;
    overflow-x: hidden;
    @media (max-width: 768px) {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  .top-btn-wrapper {
    position: fixed;
    bottom: ${(props) => (props.mobile ? '14px' : '36px')};
    right: ${(props) => (props.mobile ? '14px' : '40px')};
    z-index: 100;
    opacity: 0.5;

    &:hover {
      opacity: 0.7;
    }
  }
`;

const GridItem = styled.div<{ imgUrl: string }>`
  .grid-item {
    background-image: url(${(props) => props.imgUrl}); /* 배경 이미지 설정 */
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    padding-top: 100%;
    position: relative;
  }

  .grid-item-content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
  }
`;

const fetcher = (url) => axios.get(url).then((res) => res.data);

const AigcWork: React.FC = () => {
  const { current, setCurrent } = useWorkStore();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [selectedTab, setSelectedTab] = useState(current);
  const [projects, setProjects] = useState<Project[] | null>(null);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [isLast, setIsLast] = useState(false);
  const [renderedItemCount, setRenderedItemCount] = useState(0);
  const [preventScroll, setPreventScroll] = useState(false);
  const [currentItem, setCurrentItem] = useState(0);
  const [cursor, setCursor] = useState(false);
  const location = useLocation();
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
  const [lastRefVisible, setLastRefVisible] = useState(false);
  const [randomSequence, setRandomSequence] = useState<number[]>([]);

  const itemsPerPage = 24;

  const { data, isLoading, error } = useSWR(
    `${process.env.REACT_APP_API_URL}/api/projects/portfolio/`,
    fetcher
  );

  const calculateAnimationDelay = (index: number, page: number): number => {
    const pageIndex = index % itemsPerPage;
    return pageIndex * 0.5;
  };

  const scrollToTop = () => {
    window.scrollTo({ top: -window.innerHeight, behavior: 'smooth' });
  };

  useEffect(() => {
    const handleMouseMove = (e: MouseEvent) => {
      setMousePosition({
        x: e.clientX + window.scrollX,
        y: e.clientY + window.scrollY,
      });
    };

    window.addEventListener('mousemove', handleMouseMove);

    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
    };
  }, []);

  const viewBox = { width: 256, height: 171 };
  const svgPosition = {
    x: (mousePosition.x / window.innerWidth) * viewBox.width,
    y: (mousePosition.y / window.innerHeight) * viewBox.height,
  };

  const getQueryParams = () => {
    const queryParams = new URLSearchParams(location.search);
    const category = queryParams.get('category');
    console.log('Query parameter - category:', category);
    if (category) {
      setSelectedTab(category);
    }
  };

  const fetchItems = async () => {
    setLoading(true);
    try {
      const response = await axios.get<Results>(
        `${process.env.REACT_APP_API_URL}/api/projects/portfolio/`
        // `${process.env.REACT_APP_API_URL}/api/projects/portfolio/?page=${page}`
      );
      if (projects) {
        console.log(response.data);
        setProjects([...projects, ...response.data.results]);
      } else {
        setProjects([...response.data.results]);
      }
      setRenderedItemCount(
        (currentCount) =>
          currentCount +
          response.data.results.filter((item) =>
            selectedTab === 'all' ? true : selectedTab === item.category
          ).length
      );
      if (response.data.next === null) {
        setIsLast(true);
      }
    } catch (error) {
      console.error('Fetching error:', error);
    } finally {
      setLoading(false);
    }
  };

  const observer = useRef<IntersectionObserver>();
  const lastItemRef = useCallback(
    (node: Element | null) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver(
        (entries) => {
          if (entries[0].isIntersecting) {
            setPage((prevPage) => prevPage + 1);
          }
        },
        { rootMargin: '200px' }
      );
      if (node) observer.current.observe(node);
    },
    [loading]
  );

  useEffect(() => {
    if (!isLast && !isLoading) {
      setProjects([...data.filter((item) => item.is_public)]);
      setRandomSequence([
        ...Array.from({ length: data.length }, (_) => Math.random() * 2),
      ]);

      setRenderedItemCount(
        (currentCount) =>
          currentCount +
          data.filter((item) =>
            selectedTab === 'all' ? true : selectedTab === item.category
          ).length
      );
      // if (data.next === null) {
      //   setIsLast(true);
      // }
    }
  }, [isLoading]);

  useEffect(() => {
    // setSelectedTab(current);

    const handleScroll = () => {
      sessionStorage.setItem('scrollPosition', window.scrollY.toString());
    };

    window.addEventListener('scroll', handleScroll);

    // 페이지 마운트 시 저장된 스크롤 위치로 이동
    const savedScrollPosition = sessionStorage.getItem('scrollPosition');
    if (savedScrollPosition) {
      setTimeout(() => {
        window.scrollTo(0, parseInt(savedScrollPosition, 10));
      }, 500);
    }

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  // useEffect(() => {
  //   // !isLast && fetchItems();
  // }, [page]);

  useEffect(() => {
    if (preventScroll) {
      // 스크롤 이벤트 핸들러
      const preventScroll = (e: WheelEvent | TouchEvent) => {
        e.preventDefault();
      };

      // 스크롤 이벤트 리스너 추가
      window.addEventListener('wheel', preventScroll, { passive: false });
      window.addEventListener('touchmove', preventScroll, { passive: false });

      return () => {
        // 컴포넌트 언마운트 시 이벤트 리스너 제거
        window.removeEventListener('wheel', preventScroll);
        window.removeEventListener('touchmove', preventScroll);
      };
    }
  }, [preventScroll]);

  useEffect(() => {
    // getQueryParams();
  }, [location]);

  return (
    <ContainerStyle
      mobile={isMobile}
      onMouseEnter={() => setCursor(true)}
      onMouseLeave={() => setCursor(false)}
    >
      {!isMobile && cursor && (
        <div className="filtered-cursor">
          <div
            className="invert-mask"
            style={{
              width: '54px',
              height: '54px',
              position: 'absolute',
              left: mousePosition.x - 27,
              top: mousePosition.y - 27,
              background: 'white',
              mixBlendMode: 'difference',
              borderRadius: '50%',
              pointerEvents: 'none',
              zIndex: 1000,
            }}
          />
        </div>
      )}

      <div className="header">
        <Link to="/">
          <Logo fill={isMenuOpen ? '#fff' : '#000'} width={220} />
        </Link>
        {!isMenuOpen && (
          <HambergerNavButton
            isMenuOpen={isMenuOpen}
            setIsMenuOpen={setIsMenuOpen}
            isBlack
          />
        )}
      </div>
      <div className="title">
        <div className="title-text">
          {isMobile ? (
            <>
              Gen AI
              <br />
              CONTENT
            </>
          ) : (
            'Gen AI-CONTENT'
          )}
        </div>
      </div>
      <div className="nav-tab-wrapper">
        <AIGCNavTab setSelectedTab={setSelectedTab} selectedTab={selectedTab} />
      </div>
      <div className="works-wrapper">
        <div className="works-container">
          {projects !== null &&
            projects
              .filter((item) =>
                selectedTab === 'all' ? true : selectedTab === item.category
              )
              .map((item, index) => (
                <div key={index}>
                  <PortfolioItem
                    item={item}
                    // index={calculateAnimationDelay(index, page)}
                    index={randomSequence[index]}
                    setCurrentItem={setCurrentItem}
                  />
                </div>
              ))}
          {loading && <div>Loading...</div>}
          {lastRefVisible && <div ref={lastItemRef} />}
        </div>
      </div>
      {/*{currentItem !== 0 && !isMobile ? (*/}
      {/*  <AigcWorkDetail id={currentItem} setCurrentItem={setCurrentItem} />*/}
      {/*) : currentItem !== 0 && isMobile ? (*/}
      {/*  <AigcWorkDetailMo id={currentItem} setCurrentItem={setCurrentItem} />*/}
      {/*) : null}*/}
      <div className="top-btn-wrapper">
        <TopBottonMo onClick={() => scrollToTop()} />
      </div>
      {isMenuOpen && (
        <MainNavPanel isMenuOpen={isMenuOpen} setMenuOpen={setIsMenuOpen} />
      )}
    </ContainerStyle>
  );
};

export default AigcWork;
