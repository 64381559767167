import React from 'react';
import styled from 'styled-components';

interface IRItemProps {
  image: string | null;
  source: string;
  title: string;
  link: string;
}

const IRItemContainer = styled.a`
  //flex: 1 1 calc(300px); /* 기본 최소 너비 */
  //max-width: 100%; /* 최대 너비 */
  //min-width: 300px; /* 최소 너비 */
  width: 100%;
  min-width: 300px;
  //margin: 20px; /* 아이템 간격 */
  border: 1px solid #e0e0e0;
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  font-family: Arial, sans-serif;
  text-decoration: none;

  .image {
    width: 100%;
    height: 240px;
    object-fit: cover;
  }

  .content {
    padding: 16px;
  }

  .source {
    font-size: 16px;
    font-weight: 500;
    color: #757575;
    margin-bottom: 8px;
  }

  .title {
    font-size: 22px;
    font-weight: bold;
    color: #212121;
    margin-bottom: 12px;
    padding: 0 !important;

    display: -webkit-box !important;
    -webkit-box-orient: vertical !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    -webkit-line-clamp: 3 !important;
  }

  .read-more {
    font-size: 14px;
    color: #1e88e5;
    text-decoration: none;
  }
`;

const IRItem: React.FC<IRItemProps> = ({ image, source, title, link }) => {
  return (
    <IRItemContainer href={link} target="_blank" rel="noopener noreferrer">
      <img
        className="image"
        src={image ? image : 'images/ir_default_card.png'}
        alt={title}
      />
      <div className="content">
        <div className="source">{source}</div>
        <div className="title">{title}</div>
        <div className="read-more">Read more →</div>
      </div>
    </IRItemContainer>
  );
};

export default IRItem;
