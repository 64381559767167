import React, { useEffect, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import { Link, useNavigate } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import { ReactComponent as InstagramIcon } from '../../assets/images/Ic_Instargram.svg';
import { ReactComponent as PlaceIcon } from '../../assets/images/Ic_Place.svg';
import { ReactComponent as MailIcon } from '../../assets/images/Ic_Mail.svg';
import { ReactComponent as PhoneIcon } from '../../assets/images/Ic_Phone.svg';
import { ReactComponent as YoutubeIcon } from '../../assets/images/Ic_Youtube.svg';
import { ReactComponent as FacebookIcon } from '../../assets/images/Ic_Facebook.svg';
import axios from 'axios';
import process from 'process';
import HambergerNavButton from './HamburgerNavButton';
import {
  Link as ScrollLink,
  Button,
  Element,
  Events,
  animateScroll,
  scrollSpy,
} from 'react-scroll';

interface PropsType {
  isMenuOpen: boolean;
  setMenuOpen: (isOpen: boolean) => void;
}

interface EmailProps {
  email: string;
  assigned_to: string;
}

interface Results {
  count: number;
  next: string | null;
  previous: string | null;
  results: Project[];
}

interface Project {
  id: number;
  thumbnail: string;
  collaboration_brand: string;
  category:
    | '360-premium'
    | 'one-point-creative'
    | 'customized-creative'
    | 'luxury'
    | 'fooh'
    | 'aigc-saas'
    | 'ai-dressing';
  sub_title: string;
  date: string; // 'YYYY-MM-DD' 형식의 날짜 문자열
  kv_link?: string; // 선택적 필드로 처리, 비어있을 수 있음
  vidio_direction: 'horizontal' | 'vertical' | 'square';
  is_public: boolean;
  logo: string; // 실제 사용 시 파일 경로나 URL이 될 수 있습니다.
  logo_bg_color: string; // '#FFFFFF' 형식의 색상 코드
  description: string;
  tech: string;
  keywords: string;
}

const fadeIn = keyframes`
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
`;

const ContainerStyle = styled.div`
  position: fixed;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100vw;
  height: 100vh;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 100;
  animation: ${fadeIn} 0.5s ease-in-out forwards;

  .menu-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 60%;
    color: white;
    padding-left: 64px;

    & > a {
      font-size: 10vh;
      font-family: 'Noway', 'Pretendard', sans-serif;
      font-weight: 700;
      text-decoration: none;
      color: white;
      position: relative;
      line-height: 1.2;

      .aigc-work-chip {
        position: absolute;
        top: 5px;
        right: -40px;
        width: 84px;
        height: 36px;
        border-radius: 18px;
        background-color: #0027ff;
        font-size: 26px;
        vertical-align: middle;
        text-align: center;
        line-height: 32px;
        padding-top: 2px;

        @media (max-width: 768px) {
          top: -22px;
          right: -20px;
          padding-top: 0;
          line-height: 40px;
        }
      }
    }

    @media (max-width: 768px) {
      padding-left: 12px;
      justify-content: center;

      & > a {
        font-size: 2.8rem;
      }
    }
  }

  .menu-desc-wrapper {
    height: 60%;
    padding-right: 64px;
    align-self: center;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    @media (max-width: 1103px) {
      display: none;
    }

    .desc-title {
      color: #0027ff;
      font-size: 54px;

      & > div {
        font-family: 'Antonio', sans-serif;
        font-weight: 600;
        line-height: 1;
      }
    }

    .desc-block {
      border-top: 1px solid white;
      padding-top: 20px;
      margin-top: 20px;
      display: flex;
      flex-direction: column;
      gap: 12px;

      .desc-contact {
        color: white;
        display: flex;
        align-items: center;
        gap: 8px;
        text-decoration: none;

        &:last-child {
          margin-top: -4px;
        }

        .opacity {
          opacity: 0.5;
        }
      }
    }
  }
`;

const MainNavPanel: React.FC<PropsType> = ({ isMenuOpen, setMenuOpen }) => {
  const [loading, setLoading] = useState(false);
  const [projectCount, setProjectCount] = useState(0);

  const [emails, setEmails] = useState<string[]>([]);

  const fetchClients = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/information/email/`
      );
      if (response.data) {
        console.log('Client images:', response.data);
        setEmails(response.data.map((item: EmailProps) => item.email));
      }
    } catch (error) {
      console.error('Fetching error:', error);
    }
  };

  const fetchItems = async () => {
    setLoading(true);
    try {
      const response = await axios.get<{ total_vimeo_count: number }>(
        `${process.env.REACT_APP_API_URL}/api/projects/total-video-count/`
      );

      if (response.data) {
        console.log('response.data:', response.data);
        setProjectCount(response.data.total_vimeo_count);
      }
    } catch (error) {
      console.error('Fetching error:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchItems();
    fetchClients();
  }, []);

  useEffect(() => {
    const handleWheel = (event: WheelEvent) => {
      if (isMenuOpen) {
        event.preventDefault();
      }
    };

    const handleTouchMove = (event: TouchEvent) => {
      if (isMenuOpen) {
        event.preventDefault();
      }
    };

    window.addEventListener('wheel', handleWheel, { passive: false });
    window.addEventListener('touchmove', handleTouchMove, { passive: false });

    return () => {
      window.removeEventListener('wheel', handleWheel);
      window.removeEventListener('touchmove', handleTouchMove);
    };
  }, [isMenuOpen]);

  const handleMenuLinkClick = (scrollTarget?: string) => {
    setMenuOpen(false);

    setTimeout(() => {
      if (scrollTarget) {
        const element = document.getElementById(scrollTarget);
        if (scrollTarget === 'about') {
          animateScroll.scrollToTop();
        } else {
          if (element) {
            const offsetTop = element.offsetTop;
            animateScroll.scrollTo(offsetTop);
          }
        }
      }
    }, 600);
  };

  return (
    <ContainerStyle>
      {isMenuOpen && (
        <>
          <HambergerNavButton
            isMenuOpen={isMenuOpen}
            setIsMenuOpen={setMenuOpen}
          />
          <nav className="menu-wrapper">
            <Link
              to="/about?scroll=about"
              onClick={() => handleMenuLinkClick('about')}
            >
              ABOUT
            </Link>
            <Link to="/aigc-work">
              Gen AI-CONTENT
              <div className="aigc-work-chip">
                {loading ? '0' : projectCount}
              </div>
            </Link>
            <Link
              to="/about?scroll=aigc-product"
              onClick={() => handleMenuLinkClick('aigc-product')}
            >
              Gen AI-PRODUCT
            </Link>
            <Link
              to="/about?scroll=aigc-solution"
              onClick={() => handleMenuLinkClick('aigc-solution')}
            >
              Gen AI-SOLUTION
            </Link>
            <Link to="/news" onClick={() => setMenuOpen(false)}>
              NEWS
            </Link>
            <Link
              to="/about?scroll=contact"
              onClick={() => handleMenuLinkClick('contact')}
            >
              CONTACT
            </Link>
          </nav>
          {!isMobile && (
            <div className="menu-desc-wrapper">
              <div className="desc-title">
                <div>{"LET'S"}</div>
                <div>COLLABORATE</div>
              </div>
              <div className="desc-block">
                <div className="desc-contact">
                  <PlaceIcon style={{ marginLeft: 4 }} />
                  516, Teheran-ro, Gangnam-gu, Seoul, Republic of Korea
                </div>
                {emails.map((email) => (
                  <a
                    href={`mailto:${email}`}
                    key={email}
                    className="desc-contact"
                  >
                    <MailIcon />
                    <div>{email}</div>
                  </a>
                ))}
                <div className="desc-contact">
                  <FacebookIcon style={{ marginBottom: 6 }} />
                  <a
                    href="https://www.facebook.com/directorstech2024"
                    target="_blank"
                    style={{ textDecoration: 'none', color: '#fff' }}
                    rel="noreferrer"
                  >
                    Facebook
                  </a>
                  <InstagramIcon />
                  <a
                    href="https://www.instagram.com/directors_tech"
                    target="_blank"
                    style={{ textDecoration: 'none', color: '#fff' }}
                    rel="noreferrer"
                  >
                    Instagram
                  </a>
                  <YoutubeIcon />
                  <a
                    href=" https://www.youtube.com/@DirectorstechINC"
                    target="_blank"
                    style={{ textDecoration: 'none', color: '#fff' }}
                    rel="noreferrer"
                  >
                    Youtube
                  </a>
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </ContainerStyle>
  );
};

export default MainNavPanel;
