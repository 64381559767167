import React from 'react';
import styled from 'styled-components';
import { isMobile } from 'react-device-detect';

interface CarouselItemProps {
  image: string | null;
  link: string;
  text: string;
  date: string | null;
}

const CarouselItemContainer = styled.a`
  width: 100%;
  height: 400px;
  background-color: #000;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  text-decoration: none;
  cursor: pointer;

  .image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
  }

  .gradient-overlay {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 100px;
    background: linear-gradient(to top, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));
  }

  .text-container {
    position: absolute;
    bottom: 20px;
    width: 100%;
    text-align: center;
    z-index: 1;

    .carousel-item-text {
      width: 100%;
      padding: 0 240px;
      font-size: 28px;
      font-weight: 700;
      line-height: 1.5;
      word-wrap: break-word;
      white-space: wrap;
      display: -webkit-box !important;
      -webkit-box-orient: vertical !important;
      overflow: hidden !important;
      text-overflow: ellipsis !important;
      -webkit-line-clamp: 3 !important;
      text-shadow:
        1px 1px 2px rgba(0, 0, 0, 0.7),
        -1px -1px 2px rgba(0, 0, 0, 0.7);

      @media (max-width: 768px) {
        font-size: 20px;
        padding: 0 20px;
      }
    }

    .date {
      font-size: 20px;
      margin-top: 10px;
      opacity: 0.8;
    }
  }
`;

const CarouselItem: React.FC<CarouselItemProps> = ({
  image,
  link,
  text,
  date,
}) => {
  return (
    <CarouselItemContainer
      href={link}
      target="_blank"
      rel="noopener noreferrer"
    >
      <img
        className="image"
        src={
          image
            ? image
            : !isMobile
              ? 'images/ir_default_carousel.png'
              : 'images/id_default_carousel_mobile.png'
        }
        alt="Carousel Item"
      />
      <div className="gradient-overlay" />
      <div className="text-container">
        <div className="carousel-item-text">{text}</div>
        <div className="date">
          {date ? new Date(date).toISOString().split('T')[0] : ''}
        </div>
      </div>
    </CarouselItemContainer>
  );
};

export default CarouselItem;
